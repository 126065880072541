import React from "react";
import { Image, List, Card } from "antd";
import { isMobile } from '../../utils';
import "./characterList.css";

// 数智人形象
const CharacterList = ({ data, layout, avatarId, onClick }) => {
    const isVertical = isMobile || layout === 'vertical';

    return (
        <List
            grid={{ gutter: 16, column: isVertical ? 2 : 3 }}
            dataSource={data}
            style={{
                height: isVertical ? 350 : window.innerHeight - 344,
                overflow: "hidden auto",
            }}
            renderItem={(item) => (
                <List.Item
                    style={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Card
                        className={`card-item ${
                            avatarId === item.id ? "card-item-active" : ""
                        }`}
                        onClick={() => onClick(item)}
                        cover={
                            <Image
                                height={154}
                                preview={false}
                                src={item.preview_url}
                                style={{
                                    objectFit: 'contain',
                                }}
                            />
                        }
                    />
                    <div className="card-text">{item.avatar_name}</div>
                </List.Item>
            )}
        />
    );
};

export default CharacterList;
