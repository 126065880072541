import { forwardRef } from "react";
import { Tag } from "antd";
import "./record.css";

const Record = forwardRef((props, ref) => {
    const { data, cb } = props;
    const Template = ({ data }) => {
        return (
            <div className="template">
                <div className="tips">你可以这样问我：</div>
                    {data.role === "template"
                        ? data.content?.map((text, index) => {
                            return (
                                <div
                                    className="template-item"
                                    key={index}
                                    onClick={() => cb(text)}
                                >
                                    {text}
                                </div>
                            );
                        })
                        : null}
            </div>
        );
    };

    return (
        <div className="record-list" ref={ref}>
            {data.map((item, index) => {
                return (
                    <div
                        className={`record-item ${
                            item.role === "user" ? "user" : ""
                        }`}
                        key={index}
                    >
                        {item.type === "text" && item.role === "assistant" ? (
                            <Tag className="request-status">HTTP请求</Tag>
                        ) : null}
                        <div className="item-content" key={index}>
                            {item.role === "template" ? (
                                <Template data={item} />
                            ) : (
                                item.content
                            )}
                            <>
                                {item.role === "assistant" && item.runningTime && item.contextTotalLen ? (
                                    <div className="timeState">
                                        <div className="tag">
                                            响应时间：<b>{item.contextTotalLen}s</b>
                                        </div>
                                        <div className="tag">
                                            回复时间：<b>{item.runningTime}s</b>
                                        </div>
                                    </div>
                                ) : null}
                            </>
                        </div>
                    </div>
                );
            })}
        </div>
    );
});

export default Record;
