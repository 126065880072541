const printLog = {
    log: (...args) => {
      console.log(`%c ${args[0]} ==> `, 'color: white; font-size: 14px; background: green', args[1] ?? new Date());
    },
    error: (...args) => {
      console.log(`%c ${args[0]} ==> `, 'color: white; font-size: 14px; background: red', args[1] ?? new Date());
    },
    warn: (...args) => {
      console.log(`%c ${args[0]} ==> `, 'color: white; font-size: 14px; background: gold', args[1] ?? new Date());
    },
    info: (...args) => {
      console.log(`%c ${args[0]} ==> `, 'color: white; font-size: 14px; background: black', args[1] ?? new Date());
    },
  };
  export default printLog;