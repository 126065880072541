import { useState } from 'react';
import {  requestInterruptQueue } from '../api/request';

const useSSE = (url, agent) => {
  const [message, setMessage] = useState('');
  const [runningTime, setRunningTime] = useState('') // 回复时间;
  const [contextTotalLen, setContextTotalLen] = useState('') // 响应时间;
  const [error, setError] = useState(null);
  const [done, setDone] = useState(false);
  let priority = 1;

  const sendReq = (content, sessionId) => {
    if (!sessionId) return;
    setDone(false);
    setMessage('');
    const fetchData = async () => {
      const startTime = Date.now();
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${agent}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            chatId: sessionId,
            stream: true,
            detail: true,
            variables: {
              uid: 'asdfadsfasfd2323',
              name: '张三'
            },
            messages: [
              {
                content,
                role: 'user'
              }
            ]
          })
        });
        const stopTime = Date.now();
        const diffTime = Math.floor((stopTime - startTime) / 1000 * 100) / 100;


        const reader = response.body.getReader();
        const decoder = new TextDecoder('utf-8');
        let buffer = '';
        let sentence = '';

        while (true) {
          const { done, value } = await reader.read();
          if (done) break;

          buffer += decoder.decode(value, { stream: true });

          // 处理完整的data块
          const parts = buffer.split('\n\n');
          buffer = parts.pop(); // 保留未完成的部分

          parts.forEach(part => {
            part = part.split('\n'); //event: answer data: [DONE]

            //获取响应相关数据
            if(part[0].endsWith('flowResponses') && part[1].startsWith('data: ')) {
              const data = part[1].substring(6);
              const parseData = JSON.parse(data);
              const { runningTime } = parseData[1];
              setRunningTime(runningTime);
              setContextTotalLen(diffTime);
              return;
            }

            if (part[0].endsWith('answer') && part[1].startsWith('data: ')) {
              const jsonString = part[1].substring(6); // 移除 'data: ' 前缀
              if (jsonString.startsWith('[DONE]')) {
                setDone(true);
                return;
              }
              try {
                const parsedItem = JSON.parse(jsonString);
                if (parsedItem.choices && parsedItem.choices[0].delta && parsedItem.choices[0].delta.content) {
                  const content = parsedItem.choices[0].delta.content;
                  sentence += content;
                  setMessage(prev => prev + content);

                  // Check if the sentence ends with a period
                  if (content.includes('。') || content.includes('？') || content.includes('！')) {
                    if (sessionId) {
                      sendSentenceToQueue(sentence, sessionId, priority++);
                    }
                    sentence = ''; // Reset sentence after sending
                  }
                }
              } catch (e) {
                // 跳过无法解析的项
                console.error('JSON parse error:', e);
              }
            }
          });
        }
      } catch (err) {
        setError(err);
      }
    };

    fetchData();
  }

  const sendSentenceToQueue = async (sentence, sessionId, idx) => {
    try {
      let param = {
        tts_query: {
          content: sentence,
          ssml: false,
        },
      };
      console.log(sentence, idx)
      await requestInterruptQueue({
        sessionId,
        // priority: idx,
        param: JSON.stringify(param),
      })
      // setIdx(idx=>idx+1);
    } catch (error) {
      console.error('接管排队调用失败', error);
    }
  }

  return { message, error, done, runningTime, contextTotalLen, sendReq };
};

export default useSSE;