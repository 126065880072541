import React, { useEffect, useState } from 'react';
import { Select, Statistic, Form, Radio, Switch, Input, TimePicker, Space } from "antd";
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { isMobile } from '../../utils';

const { TextArea } = Input;
const { Countdown } = Statistic;

// 设置
const Setting = ({ form, data, layout, deadline, onEndTime, onSelectChange }) => {
    const [questions, setQuestions] = useState([]);
    const [addQuestion, setAddQuestion] = useState('');

    const isVertical = isMobile || layout === 'vertical';

    useEffect(() => {
        const questions = form.getFieldValue('init_questions');
        if (!questions) return;
        setQuestions(questions);
    }, []);

    useEffect(() => {
        console.log('questions', questions)
        form.setFieldValue('init_questions', questions);
    }, [questions]);

    // 倒计时结束 ==> 关闭直播推流
    const onFinish = () => {
        onEndTime && onEndTime();
    };

    const handleAdd = () => {
        setQuestions([...questions, addQuestion]);
    };
  
    const handleRemove = (index) => {
        const newQuestions = questions.filter((_, i) => i !== index);
        setQuestions(newQuestions);
    };
  
    const handleChange = (value, index) => {
        console.log(value, index)
        if (index >= 0) {
            const newQuestions = [...questions];
            newQuestions[index] = value;
            setQuestions(newQuestions);
        } else {
            setAddQuestion(value);
        }
    };

    return (
        <Form
            form={form}
            labelCol={{
                span: isVertical ? 5 : 4,
            }}
            wrapperCol={{
                span: 18,
            }}
            layout="horizontal"
            style={{
                height: isVertical ? 350 : window.innerHeight - 344,
                overflow: "hidden auto",
            }}
        >
            <Form.Item name="agent_name" label="智能体">
                <Select onChange={onSelectChange}>
                    {data?.map((item) => (
                        <Select.Option key={item.id} value={item.agent_name}>
                            {item.agent_name}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item name="web_search" label="联网">
                <Switch />
            </Form.Item>
            <Form.Item name="sync" label="回复">
                <Radio.Group>
                    <Radio value={true}>同步</Radio>
                    <Radio value={false}>异步</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item name="welcome" label="迎宾词">
                <TextArea
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    placeholder="请输入迎宾词，最多100个字"
                />
            </Form.Item>
            <Form.Item name="init_questions" label="初始问题">
                <Space direction="vertical" style={{ width: isVertical ? '100%' : '110%' }}>
                    {
                        questions?.map((val, index) =>
                            <div key={index}>
                                <Input
                                    value={val}
                                    style={{
                                        width: isVertical ? '100%' : '354px',
                                    }}
                                    addonAfter={<MinusOutlined onClick={() => handleRemove(index)} />}
                                    onChange={(e) => handleChange(e.target.value, index)}
                                />
                            </div>
                        )
                    }
                    <Input
                        placeholder="请输入问题"
                        style={{
                            width: isVertical ? '100%' : '354px',
                        }}
                        addonAfter={<PlusOutlined onClick={handleAdd} />}
                        onChange={(e) => handleChange(e.target.value)}
                    />
                </Space>
            </Form.Item>
            <Form.Item name="deadlineTime" label="超时时间">
                <TimePicker format="HH:mm:ss" showNow={false} />
            </Form.Item>

            <div style={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: '10px',
                visibility: 'hidden',
            }}>
                <div>停止时间：</div>
                <Countdown value={deadline} onFinish={onFinish} />
            </div>
        </Form>
    );
};

export default Setting;
