import React from 'react';
import { Form, Radio } from "antd";
import { isMobile } from '../../utils';

// 系统设置
const SystemSetting = ({ form, layout}) => {
    const isVertical = isMobile || layout === 'vertical';

    const handleChange = (e) => {
        console.log(e.target.value);
    };

    return (
        <Form
            form={form}
            labelCol={{
                span: isVertical ? 6 : 4,
            }}
            wrapperCol={{
                span: 18,
            }}
            layout="horizontal"
            style={{
                height: isVertical ? 350 : window.innerHeight - 344,
            }}
        >
            <Form.Item name="layout" label="展示方式">
                <Radio.Group onChange={handleChange}>
                    <Radio value={'horizontal'}>横屏</Radio>
                    <Radio value={'vertical'}>竖屏</Radio>
                </Radio.Group>
            </Form.Item>
        </Form>
    );
};

export default SystemSetting;
