import React, { useState, useRef } from "react";
import "./longPressButton.css";

const LongPressButton = ({ onLongPress, onRelease, delay = 500, text }) => {
    const [isPress, setIsPress] = useState(false);
    const [isLongPress, setIsLongPress] = useState(null);
    const timerRef = useRef(null);

    const handleMouseDown = () => {
        setIsPress(true);
        timerRef.current = setTimeout(() => {
            setIsLongPress(true);
            onLongPress();
        }, delay);
    };

    const handleMouseUp = () => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        isLongPress && onRelease();
        setIsLongPress(false);
        setIsPress(false);
    };

    const handleMouseLeave = () => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        isLongPress && onRelease();
        setIsLongPress(false);
        setIsPress(false);
    };

    return (
        <div
            className={`button ${isPress ? "press-status" : ""}`}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseLeave}
            onTouchStart={handleMouseDown}
            onTouchEnd={handleMouseUp}
        >
            {isLongPress ? '松开发送' : text}
        </div>
    );
};

export default LongPressButton;
