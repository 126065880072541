import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input, Button } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

import "./index.css";

export default function Login() {
    const navigate = useNavigate();

    const [username, setUsername] = useState("admin");
    const [password, setPassword] = useState("123456");

    const handleLogin = () => {
        console.log(username, password);
        navigate("/digitalPeople");
    };

    const userNameOnchange = (e) => {
        setUsername(e.target.value);
    };

    const passwordOnchange = (e) => {
        setPassword(e.target.value);
    };
    return (
        <div className="login-wrapper">
            <div className="login-container">
                <h3 className="login-title">数字人</h3>
                <div className="login-body">
                    <Input
                        className="login-input"
                        placeholder="Username"
                        value={username}
                        onChange={userNameOnchange}
                    />

                    <Input.Password
                        className="login-input"
                        placeholder="Password"
                        value={password}
                        iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                        onChange={passwordOnchange}
                    />

                    <Button onClick={handleLogin} className="submit" type="primary">
                        登录
                    </Button>
                </div>
            </div>
        </div>
    );
}
