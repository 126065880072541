export function scrollToBottom() {
    var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
    window.scrollTo({ top: scrollHeight, behavior: "smooth" });
}

export function debounce(fn, wait) {
    let timeout = null;
    return function () {
        let context = this;
        let args = arguments;
        if (timeout) clearTimeout(timeout);
        let callNow = !timeout;
        timeout = setTimeout(() => {
            timeout = null;
        }, wait);
        if (callNow) fn.apply(context, args);
    };
}

export function escapeString(str) {
    // 使用正则表达式替换特殊字符
    return str
        .replace(/\\/g, "\\") // 反斜杠转义
        .replace(/"/g, '"') // 双引号转义
        .replace(/'/g, "'") // 单引号转义
        .replace(/\n/g, "\n") // 换行转义
        .replace(/\r/g, "\r") // 回车转义
        .replace(/\t/g, "\t"); // 制表符转义
}

// s 转换成 HH:mm:ss
export function formatSecondsToTime(seconds) {
    if (!seconds) return '00:00:00';

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
  
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(secs).padStart(2, '0');
  
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

export const isMobile = /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
