import axios from 'axios';

export const request = axios.create({
    baseURL: '/digital',
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json',
    }
});

// 获取数智人列表
export const requestAvatarList = (params) => request.post(`/api/avatar/list`, params).then(res => res.data);

// 获取智能体列表
export const requestAgentList = (params) => request.post(`/api/agent/list`, params).then(res => res.data);

// 更新数智人
export const requestAvatarUpdate = (params) => request.post(`/api/avatar/update`, params).then(res => res.data);

// 更新智能体
export const requestAgentUpdate = (params) => request.post(`/api/agent/update`, params).then(res => res.data);

