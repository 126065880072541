import { message } from "antd";

export default class Recorder {
  constructor(sseClient, sampleRate = 16000) {
    this.context = null;
    this.sampleRate = sampleRate;
    this.input = null;
    this.processor = null;
    this.analyser = null;
    this.sseClient = sseClient;
    this.bufferSize = 4096;
  }

  // 检查浏览器麦克风状态
  checkAudioEnable() {
    return new Promise((resolve, reject) => {
      if (window.navigator.mediaDevices) {
        return window.navigator.mediaDevices
          .getUserMedia({ "audio": {
            channelCount: 1,
            // sampleRate: 16000,    // 采样率为16kHz
            sampleSize: 16,       // 数据位宽为16bit
            format: 'pcm',        // PCM格式
            endian: 'little-endian' // 使用小端格式
          } })
          // 用户同意赋予麦克风权限
          .then(resolve)
          .catch(e => {
            switch (e.message || e.name) {
            case "PERMISSION_DENIED":
            case "PermissionDeniedError":
              message.error("用户拒绝提供权限");
              break;
            case "NOT_SUPPORTED_ERROR":
            case "NotSupportedError":
              message.error("浏览器不支持您当前选择的设备");
              break;
            case "MANDATORY_UNSATISFIED_ERROR":
            case "MandatoryUnsatisfiedError":
              message.error("无法发现指定的硬件设备");
              break;
            default:
              message.error(`无法打开麦克风,原因：${e.code || e.name}`);
            }
            reject();
          });
      } else {
        message.error("您当前浏览器或者协议暂不支持麦克风");
        reject();
      }
    });
  }

  // 处理数据
  floatTo16BitPCM(input, offset = 0) {
    const n = input.length;
    const buffer = new ArrayBuffer(n * 2);
    const output = new DataView(buffer);

    for (let i = 0; i < input.length; i++, offset += 2) {
      let s = Math.max(-1, Math.min(1, input[i]));
      output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7FFF, true);
    }

    return output;
  }

  // 麦克风开启
  async start(successCb) {
    try {
      let stream = await this.checkAudioEnable();
      if (!this.sseClient) return;

      successCb && successCb();

      const AudioContext = window.AudioContext || window.webkitAudioContext;
      const context = new AudioContext({ sampleRate: this.sampleRate });
      const input = context.createMediaStreamSource(stream);
      const processor = context.createScriptProcessor(2048,1,1);
      const analyser = context.createAnalyser();
      const dest = context.destination;

      this.context = context;
      this.input = input;
      this.processor = processor;
      this.analyser = analyser;
    
      input.connect(analyser);
      analyser.connect(processor);
      processor.connect(dest);
    
      analyser.fftSize = 2048 * 2;
      const amplitudeArray = new Float32Array(analyser.frequencyBinCount);
      processor.onaudioprocess = (e) => {
        analyser.getFloatTimeDomainData(amplitudeArray);
        const pcm = this.floatTo16BitPCM(amplitudeArray);

        // 发送pcm数据
        this.sseClient.sendMessage(new Blob([pcm], { type: "audio/pcm" }));
      };
    } catch(e) {
      this.stop();
    }
  }

  // 麦克风关闭
  stop() {
    if (this.context) {
      this.processor.disconnect();
      this.processor = null;
      this.analyser.disconnect();
      this.analyser = null;
      this.input.disconnect();
      this.input.mediaStream.getTracks().forEach(track => track.stop());
      this.input = null;
      this.context.close();
      this.context = null;
    }
  }
}