import { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Login from "./components/Login";
import DigitalPeople from "./components/DigitalPeople";
import "./App.css";

function App() {
  useEffect(() => {
    // 禁用双指缩放
    const handleTouchStart = (event) => {
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    };

    // 禁用双击缩放
    let lastTouchEnd = 0;
    const handleTouchEnd = (event) => {
      const now = Date.now();
      if (now - lastTouchEnd <= 300) {
        event.preventDefault();
      }
      lastTouchEnd = now;
    };

    // 禁用手势缩放
    const handleGestureStart = (event) => {
      event.preventDefault();
    };

    // 添加事件监听器
    document.documentElement.addEventListener("touchstart", handleTouchStart, false);
    document.documentElement.addEventListener("touchend", handleTouchEnd, false);
    document.addEventListener("gesturestart", handleGestureStart, false);

    // 组件卸载时移除事件监听器
    return () => {
      document.documentElement.removeEventListener("touchstart", handleTouchStart, false);
      document.documentElement.removeEventListener("touchend", handleTouchEnd, false);
      document.removeEventListener("gesturestart", handleGestureStart, false);
    };
  }, []);

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<Login />} />
          <Route path="/digitalPeople" element={<DigitalPeople />} />
        </Routes>
      </Router>
    </div>
  );
}
export default App;
