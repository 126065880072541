import ReconnectingWebSocket from "reconnecting-websocket";
import { v4 as uuidv4 } from "uuid";

const HeartbeatValue = 0;

export default class Sse extends EventTarget {
  constructor(server) {
    super();
    this.sseSource = null;
    this.isConnection = false;
    this.pingType = null;
    this.server = server;
  }

  /**
   * 初始化sse
   * @param url
   * @param authToken
   * @param deviceId
   */
  init(url, authToken, deviceId) {
    const fullUrl = `${this.server}${url}`;
    const socket = new ReconnectingWebSocket(fullUrl);
    this.sseSource = socket;
    this.authToken = authToken;
    this.deviceId = deviceId;
    this._bindEvent();
  }

  _bindEvent() {
    if (!this.sseSource) return false;
    this.sseSource.onopen = event => {
      console.log("onopen event");
      if (!this.isConnection) {
        this.dispatchEvent(new CustomEvent("opened"));
        this.isConnection = true;
        this.sendStarterPacket();
      }
    };
    this.sseSource.onmessage = event => {
      const message = event.data;
      if (message) {
        try {
          const data = JSON.parse(message);
          if (data.type === HeartbeatValue) {
            console.log(data.msg, "heartbeat");
          } else {
            this._messageEvent({ detail: data });
          }
        } catch(e) {
          console.info(message);
        }
      }
    };
    this.sseSource.onclose = err => {
      const { code } = err;
      if (code === 1000) {
        this.dispatchEvent(new CustomEvent("close"));
        this.close();
        console.log(`服务器关闭【${code}】`);
      }
    };
    this.sseSource.onerror = error => {
      this._errorEvent({ detail: error });
    };
  }

  sendStarterPacket() {
    const starterPacket = {
      auth: this.authToken,
      type: "ASR5",
      device: this.deviceId,
      session: uuidv4(),
      asr: {
        language: "zh-CN",
        mic_volume: 1.0,
        subtitle: "",
        subtitle_max_length: 0,
        intermediate: false,
        sentence_time: false,
        word_time: false,
        cache_url: false,
        pause_time_msec: 500
      }
    };
    this.sseSource.send(JSON.stringify(starterPacket));
  }

  sendMessage(message) {
    if (this.isConnection) {
      this.sseSource.send(message);
    }
  }

  _messageEvent(message) {
    this.dispatchEvent(new CustomEvent("message", message));
  }

  _errorEvent(err) {
    this.dispatchEvent(new CustomEvent("error", err));
  }

  close() {
    if (!this.sseSource) return false;
    this.sseSource.close();
    this.sseSource = null;
    this.isConnection = false;
  }
}
