import axios from 'axios';

export const request = axios.create({
    baseURL: '/aigc',
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json',
    }
});

// 获取Token
export const requestToken = (params) => request.post(`/api/uc/v1/access/api/token`, params).then(res => res.data);

// 新建房间
export const requestCreate = (params) => request.post(`/api/2dvh/v2/material/live/channel/create`, params).then(res => res.data);

// 开启直播
export const requestStart = (params) => request.post(`/api/2dvh/v1/material/live/channel/start`, params).then(res => res.data);

// 离开直播
export const requestClose = (params) => request.post(`/api/2dvh/v1/material/live/channel/close`, params).then(res => res.data);

// 查询会话状态
export const requestStat = ({ sessionId }) => request.get(`/api/2dvh/v1/material/live/channel/stat?sessionId?sessionId=${sessionId}`).then(res => res.data);

// 直播状态监测
export const requestHeartbeat = (params) => request.post(`/api/2dvh/v1/material/live/channel/heartbeat`, params).then(res => res.data);

// 直播接管
export const requestInterrupt = (params) => request.post(`/api/2dvh/v2/material/live/channel/command`, params).then(res => res.data);

// 刷新token
export const requestRefresh = (params, config) => request.post(`/api/uc/v1/access/api/token/refresh`, params, config).then(res => res.data);

// 获取正在直播的sessionid
export const checklive = () => request.get(`/api/2dvh/v1/material/live/channel/running/list`).then(res => res.data);

// 直播接管排队（可中断）
export const requestInterruptQueue = (params) => request.post(`/api/2dvh/v1/material/live/channel/command/queue`, params).then(res => res.data);

// 直播接管取消
export const requestInterruptCancel = (params) => request.post(`/api/2dvh/v1/material/live/channel/command/cancel`, params).then(res => res.data);

// 直播接管排队查询
export const requestInterruptQueueList = (params) => request.post(`/api/2dvh/v1/material/live/channel/command/queue/list`, params).then(res => res.data);
