import axios from 'axios';
import AgoraRTC from "agora-rtc-sdk-ng"

// ⚠️使用前请确保已正确引入声网js-sdk
export default class Rtc extends EventTarget {
  constructor() {
    super();
    this.createClientOptions = {
      mode: "live", //rtc
      codec: "vp8",
      role: "host",
    };
    this.localRTCTrack = {
      localAudioTrack: null,
      localVideoTrack: null,
    };
    this.setClientRoleoptions = {
      appId: "",
      channel: "",
      role: "audience",
      token: "",
      uid: "",
    };
    this.client = null;
    this.remoteAudioTrack = null;
    this.remoteVideoTrack = null;
    this.joining = false; // 是否在接入中
    this.sessionId = null;
    this.videoCanPlay = () => {};
  }

  /**
   * 开始
   */
  init({
    appId = "",
    token = null,
    remotePlayerContainer,
    channel = "",
    customerUid = "",
    sessionId = null,
    role = "host",
    videoCanPlay,
  }) {
    this.remotePlayerContainer = remotePlayerContainer;
    this.setClientRoleoptions.appId = appId;
    this.setClientRoleoptions.token = token;
    this.setClientRoleoptions.channel = channel;
    this.setClientRoleoptions.uid = customerUid;
    this.sessionId = sessionId;
    this.createClientOptions.role = role;
    this.videoCanPlay = videoCanPlay;
    
    // 创建rtc 实例
    try {
      // eslint-disable-next-line
      this.client = AgoraRTC.createClient(this.createClientOptions);
      // eslint-disable-next-line
      AgoraRTC.setParameter("rtc.enable_vos_zero_rtt", true);
      // 进入频道
      console.log(appId, channel, token, customerUid, "join 的频道信息");
      this.client.join(appId, channel, token || null, Number(customerUid) || null);
      this.bindEvent();
    } catch (err) {
      console.log(err);
    } finally {
      console.log("创建rtc 实例 join channel");
    }
  }

  /**
   * 拉取token
   * */ 
  fetchToken(rtcChannelId) {
    return axios.post("/api/2dvh/v1/material/rtc/token/audience", {
      rtcChannelId,
      sessionId: this.sessionId
    })
      .then(res => res.data);
  }

  /**
   * 绑定事件
   */
  bindEvent() {
    this.client.on("user-published", this.userPublished.bind(this));

     // 收到 token-privilege-will-expire 回调时，从服务器重新申请一个 Token，并调用 renewToken 将新的 Token 传给 SDK
     this.client.on("token-privilege-will-expire", async () => {
      let token = await this.fetchToken(this.setClientRoleoptions.channel);
      console.log("ReFreshing the channel with new Token");
      await this.client.renewToken(token);
    });

    // 收到 token-privilege-did-expire 回调时，从服务器重新申请一个 Token，并调用 join 重新加入频道。
    this.client.on("token-privilege-did-expire", async () => {
      let token = await this.fetchToken(this.setClientRoleoptions.channel);
      console.log("Rejoining the channel with new Token");
      await this.client.join(this.setClientRoleoptions.appId, this.setClientRoleoptions.channel, token || null, Number(this.setClientRoleoptions.uid) || null);
    });
  }

  /**
   * 有用户进入 频道事件
   * @returns {Promise<void>}
   */
  async userPublished(user, mediaType) {
    console.log("---------userPublished--------- event", user, mediaType);
    const that = this;
    this.joining = true;
    await this.client.subscribe(user, mediaType);
    if (mediaType === "video") {
      const remoteVideoTrack = user.videoTrack;
      that.remoteVideoTrack = remoteVideoTrack;
      that.playVideo();
    }
    if (mediaType === "audio") {
      const remoteAudioTrack = user.audioTrack;
      that.remoteAudioTrack = remoteAudioTrack;
      that.playAudio();
    }
    this.dispatchEvent(new CustomEvent("user-published"));
  }

  playVideo() {
    console.log("-------playVideo---------");
    this.remoteVideoTrack.play(this.remotePlayerContainer, { fit: "contain" });
    // this.log(Date.now());
    // 监听视频流解码成功的事件
    this.remoteVideoTrack.on("first-frame-decoded", () => {
      this.videoCanPlay?.();
    });
  }

  playAudio() {
    console.log("-------playAudio---------");
    this.remoteAudioTrack.play();
  }

  /**
   * 离开事件
   */
  async leaveEvent() {
    this.remotePlayerContainer?.remove();
    if (!this.client) return;
    await this.client.leave();
  }

  getVideoState() {
    const state = this.rtcClient.getRemoteVideoStats();
    console.log(state);
  }
}
