import React, { useEffect, useState, useRef } from "react";
import {
    message,
    Input,
    Button,
    Image,
    Tooltip,
    Modal,
    Tabs,
    Form,
    Space,
    Spin,
} from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import md5 from "md5";
import dayjs from 'dayjs';
import "dayjs/locale/zh-cn";

import {
    request,
    requestToken,
    requestCreate,
    requestStart,
    requestClose,
    requestStat,
    requestHeartbeat,
    requestInterrupt,
    requestRefresh,
    checklive,
    requestInterruptCancel,
    requestInterruptQueueList,
} from "../../api/request";
import {
    requestAvatarList,
    requestAgentList,
    // requestAvatarUpdate,
    requestAgentUpdate
} from "../../api/requestAgent";
import Sse from "../../modules/sse/index";
import Rtc from "../../modules/webrtc/rtc";
import recorder from "../../modules/recorder";
import useSSE from "../../hooks/useSSE";
import Record from "./record";
import Setting from "./setting";
import SystemSetting from "./systemSetting";

import CharacterList from "./characterList";
import LongPressButton from "./longPressButton";

import { APP_ID, APP_KEY } from "../../static/constants";
import console from "../../utils/console";
import { debounce, formatSecondsToTime, isMobile } from "../../utils";
import "./index.css";


const ERROR_CODES = (code) => [9, 99, 50, 5, 99999999].includes(code);
const rtcObj = {
    channelId: null, // 对话参数 - channelId
    sessionId: null, // 对话参数 - sessionId
    sessionToken: null, // 麦克风接管参数 - sessionToken
    isConnect: null, // 对话状态， false - 未对话， true - 对话中
};

const bannerImg = require("../../assets/title.png");

const microphoneIcon = require("../../assets/microphone-icon.png");
const volumeIcon = require("../../assets/volume-icon.png");
const playIcon = require("../../assets/play-icon.png");
const pauseIcon = require("../../assets/pause-icon.png");
const keyboadIcon = require("../../assets/keyboad-icon.png");
const settingIcon = require("../../assets/setting-icon.png");
const muteIcon = require("../../assets/mute-icon.png");
const sendMessageIcon = require("../../assets/send-message-icon@2x.png");

let isPush = false;
let isPlayEnd = false;
function DigitalPeople() {
    const [accessToken, setAccessToken] = useState("");
    const [refreshToken, setRefreshToken] = useState("");
    const [rtcObject, setRtcObject] = useState(rtcObj);
    const [inputValue, setInputValue] = useState("");
    const [status, setStatus] = useState(-1); // 对话间状态，-1:未开始，0:开始创建，1:创建完成&对话中
    const [sessionId, setId] = useState("");
    const [commandTraceId, setCommandId] = useState("");
    const [startTime, setStartTime] = useState(0); // 统计开启对话推流时间
    const [tabIndex, setTabIndex] = useState("1"); // 设置tabs
    const [modalVisible, setModalVisible] = useState(false); // 设置弹窗展示
    const [avatarList, setAvatarList] = useState([]);
    const [agentList, setAgentList] = useState([]);

    const [canPlay, setCanplay] = useState(false); // 数智人是否展示
    const [talkMethod, setTalkMethod] = useState("audio"); // 对话方式 audio|text
    const [avatarId, setAvatarId] = useState(8); // 设置默认数智人形象
    const [agentId, setAgentId] = useState(1); // 设置默认智能体
    const curAvatar = avatarList?.find(v => v.id === avatarId); // 用户选中的数智人
    const curAgent = agentList?.find(v => v.id === agentId); // 用户选中的智能体

    const [recordList, setRecordList] = useState([]); // role: template | assistant | user

    const [form] = Form.useForm();
    const pageRef = useRef(null);
    const recordRef = useRef(null); // 聊天记录容器
    const rtcClient = useRef(null); // 声网rtc实例
    const heartbeatTimer = useRef(null); // 心跳检测
    const sseAudioClient = useRef(null); // 麦克风接管socket实例
    const audioOnlineInstance = useRef(null); // 麦克风接管实例
    const {
        message: msg,
        error,
        done,
        runningTime,
        contextTotalLen,
        sendReq,
    } = useSSE("/cmcc/api/v1/chat/completions", curAgent?.agent_key);

    useEffect(() => {
        fetchToken();
        fetchAvatar();
        fetchAgent();
        // 刷新Token接口（每两次调用token刷新接口的时间间隔不得低于3小时）
        const NEXT_TIME = 1000 * 60 * 60 * 3 + 3000;
        let timer = setInterval(() => {
            fetchRefreshToken();
        }, NEXT_TIME);

        document.addEventListener('contextmenu', preventDefault);

        return () => {
            clearInterval(timer);
            clearInterval(heartbeatTimer.current);
            document.removeEventListener('contextmenu', preventDefault);
        };
    }, []);

    const preventDefault = (e) => {
        e.preventDefault(); // 禁用右键菜单
    }

    useEffect(() => {
        setRecordList([
            {
                role: "template",
                content: curAgent?.init_questions,
            },
        ]);
    }, [agentList]);

    useEffect(() => {
        // 重置设置数据
        if (!modalVisible) {
            setTabIndex("1");
            form.resetFields();
        }
    }, [modalVisible]);

    useEffect(() => {
        // 人物渲染结束，发起文字接管
        if (canPlay) {
            setStartTime(Date.now());
            handleInterrupt({
                command: 1,
                text: curAgent.welcome,
            }, sessionId);
        }
    }, [canPlay]);

    // 接收后台传递的数据，并实时更新在聊天记录中
    useEffect(() => {
        let displayText = "";
        displayText += msg;

        if (!done && displayText) {
            const newItems = [...recordList];

            // 先push一条空数据
            if (!isPush) {
                newItems.push({ role: "assistant", type: talkMethod, content: "", runningTime: null, contextTotalLen: null });
                isPush = true;
            }

            // 实时更新内容
            newItems.map((i, k) => {
                if (k === newItems.length - 1 && i.role === "assistant") {
                    i.content = displayText;
                }
            });

            // 边更新边滚动到底部
            recordRef.current?.scrollTo({
                top: recordRef.current?.scrollHeight,
                behavior: "smooth",
            });
            // 更新状态
            setRecordList(newItems);
        }

        // 当前数据更新完毕，重置状态 & 存到本地
        if (done && runningTime && contextTotalLen) {
            recordRef.current?.scrollTo({
                top: recordRef.current?.scrollHeight,
                behavior: "smooth",
            });
            setRecordList(prev => {
                const index = prev.findLastIndex(item => item.role === "assistant");

                if (index !== -1) {
                  const newData = [...prev];
                  newData[index] = {
                    ...newData[index],
                    runningTime,
                    contextTotalLen,
                  };

                  return newData;
                }
                return prev;
            });
            isPush = false;
        }
    }, [msg, done, isPush, runningTime, contextTotalLen]);

    // 对话列表更新时，显示最新消息
    useEffect(() => {
        recordRef.current?.scrollTo({
            top: recordRef.current?.scrollHeight,
            behavior: "smooth",
        });
    }, [recordList.length]);

    // 获取token
    const fetchToken = async () => {
        const CURRENT_TIMETEMP = Date.now();
        const sign = md5(`${APP_ID}${CURRENT_TIMETEMP}${APP_KEY}`);

        try {
            const res = await requestToken({
                appId: APP_ID,
                timestamp: CURRENT_TIMETEMP.toString(),
                sign,
                grantType: "sign",
            });

            if (res.code === 0) {
                const { accessToken } = res.data;
                setAccessToken(accessToken);
                request.defaults.headers.common[
                    "Authorization"
                ] = `Bearer ${accessToken}`;
                console.log("获取TOKEN", accessToken);
            }
        } catch (error) {
            console.error("获取TOKEN出错", error);
        }
    };

    const fetchRefreshToken = async () => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${refreshToken}`,
                },
            };
            const res = await requestRefresh(
                {
                    appId: APP_ID,
                    grantType: "refreshToken",
                },
                config
            );
            if (res.code === 0) {
                const { accessToken, refreshToken } = res.data;
                setAccessToken(accessToken);
                setRefreshToken(refreshToken);
            }
        } catch (error) {
            console.error("刷新TOKEN出错", error);
        }
    };

    // 获取数智人列表
    const fetchAvatar = async () => {
        try {
            const res = await requestAvatarList({
                integer: 0,
            });
            if (res.data.avatars.length) {
                setAvatarList(res.data.avatars);
            }
        } catch (error) {
            console.error("获取数智人列表出错", error);
        }
    }

    // 获取智能体列表
    const fetchAgent = async () => {
        try {
            const res = await requestAgentList({});
            if (res.data.agents.length) {
                setAgentList(res.data.agents);
            }
        } catch (error) {
            console.error("获取Agent出错", error);
        }
    }

    // 视频流解码成功，并且人物渲染出现
    const videoCanPlay = () => {
        setCanplay(true);
    };

    // 新建房间
    const createRoom = debounce(async (id) => {
        try {
            setStatus(0);
            setRecordList([
                {
                    role: "template",
                    content: curAgent?.init_questions,
                },
            ]);
            const res = await requestCreate({
                param: avatarList.find(v => v.id === id).avatar_model,
            });
            const { code, data } = res;
            // 错误
            if (code !== 0 || ERROR_CODES(data?.status)) {
                setRtcObject({
                    ...rtcObject,
                    sessionId: null,
                });
                return;
            }
            const {
                sessionId,
                rtcAppId,
                rtcChannelId,
                rtcUid,
                rtcToken,
                sessionToken,
            } = data;
            setId(sessionId);

            const { status } = data;
            // 状态：对话已关闭
            if (status === 5 || status === 50) {
                setRtcObject({
                    ...rtcObject,
                    isConnect: false,
                });
                return;
            }

            if (status === 32) {
                setTimeout(() => {
                    getLiveStatus(sessionId);
                }, 3000);
                return;
            }

            setRtcObject({
                channelId: rtcChannelId,
                sessionId,
                sessionToken,
                isConnect: true,
            });

            isPlayEnd = false;

            // 状态：对话间创建完成
            if (status === 35) {
                // 创建成功之后初始化RTC
                rtcClient.current = new Rtc();
                rtcClient.current.init({
                    appId: rtcAppId,
                    token: rtcToken,
                    remotePlayerContainer: document.getElementById("liveVideo"),
                    channel: rtcChannelId,
                    customerUid: rtcUid,
                    sessionId,
                    videoCanPlay,
                });
                console.log("新建房间成功", res.data.status);
                await startLive(sessionId);
                await sendHeartbeat(sessionId);
            }
        } catch (error) {
            setStatus(-1);
            handleClose(sessionId);
            message.error("啊哦出错啦");
            console.error("新建房间出错", error);
        }
    }, 800);

    // 开启对话
    const startLive = async (sessionId) => {
        try {
            const res = await requestStart({ sessionId });
            if (res.code === 0) {
                console.log("开启对话", res.data);
                // 麦克风接管
                setStatus(1);
                await initAudioSSE(sessionId);
            }
        } catch (error) {
            setStatus(-1);
            handleClose(sessionId);
            console.error("开启对话出错", error);
        }
    };

    /**
     * 对话接管 - 文字/mp3接管
     *
     * @param {Integer} command - 接管类型（1 - 文字， 2 - mp3）
     * @param {String} text - 接管文字内容
     * @param {String} audio - 接管mp3
     */
    const handleInterrupt = async ({ command = 1, text, audio }, sessionId) => {
        try {
            if (!sessionId) return message.error("对话未开启");
            if (!accessToken) return message.error("token丢失");
            if (commandTraceId) {
                await requestInterruptCancel({
                    sessionId,
                    commandTraceId,
                });
            }
            let param;
            // 数据封装
            if (command === 1) {
                param = {
                    tts_query: {
                        content: text,
                        ssml: false,
                    },
                };
            } else {
                param = {
                    audio, // mp3上传后的文件链接
                };
            }
            const res = await requestInterrupt({
                sessionId,
                param: JSON.stringify(param),
            });
            if (res.code === 0) {
                // commandTraceId - 可用于队伍插队/取消单次接管
                const { isSuccess, commandTraceId } = res.data;
                setCommandId(commandTraceId);
                if (isSuccess) {
                    console.log("对话接管成功");
                }
            }
        } catch (error) {
            console.error("接管出错", error);
        }
    };

    /**
     * 对话状态监测(对话期间保持心跳定期发送, 建议60s)
     *
     */
    const sendHeartbeat = async (sessionId) => {
        if (isPlayEnd) return;
        if (heartbeatTimer.current) clearInterval(heartbeatTimer.current);
        heartbeatTimer.current = setTimeout(async () => {
            try {
                const res = await requestHeartbeat({ sessionId });
                if (res.code === 0) {
                    console.log("对话状态监测", res);
                }
            } catch (error) {
                console.error("对话状态监测出错", error);
            } finally {
                sendHeartbeat(sessionId);
            }
        }, 60 * 1000);
    };

    /**
     * 查询会话状态
     * @param {String} sessionId - 会话的唯一标识
     * @return {Integer} res.data.status - 任务状态：32:视频流创建中, 35:视频流创建完成, 其他：参考文档
     */
    const getLiveStatus = async (sessionId) => {
        try {
            const res = await requestStat({ sessionId });
            if (res.code === 0) {
                console.log("查询会话状态", res.data);
            }
        } catch (error) {
            console.error("查询会话状态出错", error);
            // 网络错误，尝试补发
            if (error.message === "Network Error") {
                setTimeout(() => {
                    getLiveStatus(sessionId);
                }, 3000);
            } else {
                setRtcObject({
                    ...rtcObject,
                    isConnect: null,
                    sessionId: null,
                });
            }
        }
    };

    /**
     * 对话接管 - 麦克风接管socket实例
     *
     */
    const initAudioSSE = async (sessionId) => {
        sseAudioClient.current = new Sse("wss://aigc.softsugar.com");
        sseAudioClient.current.init(
            `/api/voice/stream/v3?Authorization=Bearer ${accessToken}`,
            accessToken,
            "dwt"
        );

        sseAudioClient.current.addEventListener(
            "opened",
            (e) => {
                console.log("语音接管已开启", e);
            },
            false
        );

        sseAudioClient.current.addEventListener(
            "message",
            async (message) => {
                if (message.detail) {
                    const { status, asr } = message.detail;
                    if (status === "ok") {
                        console.log("asr return text", asr?.text);
                        if (asr?.text) {
                            await requestCancelQueue();
                            setRecordList((prev) => [
                                ...prev,
                                { role: "user", content: asr?.text },
                            ]);
                            sendReq(asr?.text, sessionId);
                        }
                    }
                }
            },
            false
        );

        sseAudioClient.current.addEventListener(
            "close",
            () => {
                console.log("语音接管已关闭");
            },
            false
        );
    };

    /**
     * 麦克风接管 - 开启麦克风
     */
    const openAudioInterrupt = debounce(async () => {
        if (!rtcObject.isConnect) return message.error("对话未开启");
        await requestCancelQueue().then(() => {
            if (audioOnlineInstance.current) audioOnlineInstance.current.stop();
            audioOnlineInstance.current = new recorder(sseAudioClient.current);
            audioOnlineInstance.current.start(() => {
                console.log("开启麦克风");
            });
            setStartTime(Date.now());
        }).catch ((error) => {
            console.error("查询对话状态出错", error);
        });
    }, 800);

    /**
     * 麦克风接管 - 关闭麦克风
     */
    const closeAudioInterrupt = () => {
        audioOnlineInstance.current?.stop();
        // socket发送结束帧
        sseAudioClient.current?.sendMessage(
            JSON.stringify({
                signal: "eof",
            })
        );
        console.log("关闭麦克风");
    };

    // 离开对话
    const handleClose = async (sessionId) => {
        try {
            if (!sessionId) {
                const res = await checklive();
                if (res.data[0]?.sessionId) {
                    await leaveRoom(res.data[0].sessionId);
                }
                return;
            }
            await leaveRoom(sessionId);
        } catch (error) {
            setStatus(-1);
            console.error("离开对话出错", error);
        }
    };

    const leaveRoom = async (sessionId) => {
        try {
            const res = await requestClose({ sessionId });
            if (res.code === 0) {
                console.log("离开对话", res);
            }
        } catch (error) {
            console.error("离开对话出错", error);
        } finally {
            // 恢复初始状态
            closeAudioInterrupt();
            setRtcObject({
                channelId: null,
                isConnect: null,
                sessionId: null,
                sessionToken: null,
            });
            isPlayEnd = true;
            setStatus(-1);
            setStartTime(0);
            setCanplay(false);
            sseAudioClient.current?.close();
        }
    };

    // 开始对话 or 结束对话
    const handleToggleCreate = () => {
        setStatus((pre) => {
            if (pre === -1) {
                createRoom(avatarId);
                return 0;
            } else if (pre === 1) {
                handleClose(sessionId);
                return -1;
            }
            return -1;
        });
    };

    // 取消接管队列
    const requestCancelQueue = async () => {
        try {
            const res = await requestInterruptQueueList({
                sessionId,
            });
            const { commandTraceIds } = res.data || {};
            console.log(
                "requestInterruptQueueList",
                commandTraceIds
            );
            if (commandTraceIds.length) {
                await requestInterruptCancel({
                    sessionId,
                    commandTraceId: "-1",
                });
            }
        } catch (error) {

        }
    }

    // 发送文字内容
    const handleSendMessage = async () => {
        if (!rtcObject.isConnect) return message.error("对话未开启");
        if (!inputValue) return message.error("请先输入内容");
        setRecordList((pre) => [...pre, { role: "user", content: inputValue }]);
        await requestCancelQueue();
        sendReq(inputValue, sessionId);
        setInputValue("");
        setStartTime(Date.now());
    };

    // 回车键发送文字内容
    const handleKeyDown = async (event) => {
        if (!rtcObject.isConnect) return message.error("对话未开启");
        if (!inputValue) return message.error("请先输入内容");
        if (event.key === "Enter") {
            setRecordList((pre) => [
                ...pre,
                { role: "user", content: inputValue },
            ]);
            await requestCancelQueue();
            sendReq(inputValue, sessionId);
            setInputValue("");
            setStartTime(Date.now());
        }
    };

    // 数智人形象选中
    const handleSelectCard = async (val) => {
        setModalVisible(false);
        setAvatarId(val.id);
        setRecordList([
            {
                role: "template",
                content: curAgent?.init_questions,
            },
        ]);
    
        if (rtcObject.isConnect) {
            message.info({content: '正在为您切换数智人形象...', duration: 3});
            await handleClose(sessionId);
            createRoom(val.id);
        }
    };

    // 设置tabs切换
    const handleTabsChange = (activeKey) => {
        const { agent_name, web_search, sync, idle_timeout, welcome, init_questions } = curAgent || {};
        setTabIndex(activeKey);

        if (activeKey === '2') {
            form.setFieldsValue({
                ...form,
                web_search,
                sync,
                deadlineTime: dayjs(formatSecondsToTime(idle_timeout), 'HH:mm:ss'),
                welcome: welcome,
                init_questions,
                agent_name,
            });
        } else if (activeKey === '3') {
            const layout = localStorage.getItem('layout') || 'horizontal';
            form.setFieldsValue({
                ...form,
                layout,
            });
        };
    };

    // 设置保存
    const handleSaveSetting = () => {
        if (tabIndex === "1") {
        } else if (tabIndex === "2") {
            form.validateFields().then(async (value) => {
                const sec = value.deadlineTime.hour() * 3600 + value.deadlineTime.minute() * 60 + value.deadlineTime.second();
                console.log(111, {value, sec, curAgent});
                if (value.init_questions.some(v => v === '')) return message.error('请输入需要设置的问题～');
                const { agent_name, web_search, init_questions, sync, welcome} = value;
                try {
                    await requestAgentUpdate({
                        agent: {
                            id: curAgent.id,
                            agent_name,
                            agent_key: curAgent.agent_key,
                            web_search,
                            sync,
                            init_questions,
                            welcome,
                            idle_timeout: sec,
                        }
                    });
                    await fetchAgent();
                } catch (error) {
                    message.error('系统开小差啦，请稍后再试～');
                };
            });
        } else if (tabIndex === "3")  {
            form.validateFields().then(async (value) => {
                const { layout } = value;
                localStorage.setItem('layout', layout);
            });
        };
        setModalVisible(false);
    };

    // 对话模版选择
    const handleClickTemplateItem = async (text) => {
        if (!rtcObject.isConnect) return message.error("对话未开启");
        if (!text) return message.error("请先输入内容");
        setStartTime(Date.now());
        setRecordList((pre) => [...pre, { role: "user", content: text }]);
        await requestCancelQueue();
        setTalkMethod('text');
        sendReq(text, sessionId);
    };

    // 对话方式切换
    const handleToggleTalkMethod = () => {
        if (talkMethod === "audio") {
            setTalkMethod("text");
        } else {
            setTalkMethod("audio");
        }
    };

    const autoCloseRoom = () => {
        handleClose(sessionId);
        message.info('操作超时，已为您关闭对话～');
    };

    const handleSelectChange = (id) => {
        setAgentId(id);
        const selectAgent = agentList.find(v => v.id === id);
        const { agent_name, web_search, sync, idle_timeout, welcome, init_questions } = selectAgent;
        form.setFieldsValue({
            ...form,
            web_search,
            sync,
            deadlineTime: dayjs(formatSecondsToTime(idle_timeout), 'HH:mm:ss'),
            welcome: welcome,
            init_questions,
            agent_name,
        });
    }

    const layout = localStorage.getItem('layout');
    const items = [
        {
            label: "数智人形象",
            key: "1",
            children: (
                <CharacterList
                    data={avatarList}
                    layout={layout}
                    avatarId={avatarId}
                    onClick={handleSelectCard}
                />
            ),
        },
        { 
            label: "其他设置",
            key: "2",
            children: (
                <Setting
                    form={form}
                    data={agentList}
                    layout={layout}
                    deadline={ startTime + curAgent?.idle_timeout * 1000}
                    onEndTime={autoCloseRoom}
                    onSelectChange={handleSelectChange}
                />
            )
        },
        { 
            label: "系统设置",
            key: "3",
            children: (
                    <SystemSetting form={form} layout={layout}/>
            )
        },
    ];

    return (
        <div className={`page-digital ${(!isMobile && layout === 'vertical') ? 'vertical' : ''}`} ref={pageRef}>
            <div className="page-container">
                {/* banner */}
                <Image
                    width={'100%'}
                    height='auto'
                    preview={false}
                    src={bannerImg}
                    wrapperStyle={{
                        position: 'absolute',
                        top: 0,
                        transformOrigin: 'bottom',
                        zIndex: 100
                    }}
                />
                {/* 数智人区域 */}
                <div className="character-area">
                    {
                        status === -1 && curAvatar?.preview_url && 
                        <Image
                            width={'100%'}
                            height={isMobile || layout === 'vertical' ? '100%' : '100vh'}
                            preview={false}
                            src={curAvatar.preview_url}
                            wrapperStyle={{
                                position: 'absolute',
                                bottom: 0,
                                transformOrigin: 'bottom',
                            }}
                        />
                    }
                    <Spin spinning={ status !== -1 && !canPlay } indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} style={{maxHeight: '100%'}}>
                        <div id="liveVideo"></div>
                    </Spin>
                </div>

                {/* 问答区域 */}
                <div className="main-area">
                    <div className="content-area">
                        <Record
                            data={recordList}
                            ref={recordRef}
                            cb={debounce(handleClickTemplateItem, 800)}
                        />
                    </div>

                    <div className="input-area">
                        <Image
                            width={54}
                            height={54}
                            preview={false}
                            onClick={() => handleToggleTalkMethod()}
                            src={
                                talkMethod === "text"
                                    ? keyboadIcon
                                    : microphoneIcon
                            }
                        />
                        <div className="input-mode">
                            {talkMethod === "text" ? (
                                <Input
                                    className="input-box"
                                    placeholder="请输入你的问题"
                                    variant="borderless"
                                    onPressEnter={handleKeyDown}
                                    onChange={(e) =>
                                        setInputValue(e.target.value)
                                    }
                                    value={inputValue}
                                    style={{
                                        height: "100%",
                                        paddingLeft: 5,
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                    addonAfter={
                                        <Image
                                            width={28}
                                            height={28}
                                            preview={false}
                                            onClick={() => handleSendMessage()}
                                            src={sendMessageIcon}
                                            wrapperStyle={{
                                                width: "28px",
                                                height: "28px",
                                                marginLeft: "0",
                                                marginRight: "5px",
                                            }}
                                        />
                                    }
                                />
                            ) : (
                                <LongPressButton
                                    onLongPress={() => openAudioInterrupt()}
                                    onRelease={() => closeAudioInterrupt()}
                                    delay={500}
                                    text={"长按说话"}
                                />
                            )}
                        </div>
                        <Image
                            width={54}
                            height={54}
                            preview={false}
                            src={talkMethod === "text" ? muteIcon : volumeIcon}
                        />
                    </div>
                </div>
            </div>

            {/* 右上角开启与设置 */}
            <div className="setting-area">
                <Tooltip
                    placement="left"
                    title={!(isMobile || layout === 'vertical') && (status === -1 ? "开始" : "结束")}
                >
                    <Image
                        width={40}
                        height={40}
                        preview={false}
                        onClick={() => handleToggleCreate()}
                        src={status === -1 ? playIcon : pauseIcon}
                        wrapperStyle={{
                            marginBottom: 8,
                        }}
                    />
                </Tooltip>
                <Tooltip placement="left" title={!(isMobile || layout === 'vertical') && "设置"}>
                    <Image
                        width={40}
                        height={40}
                        preview={false}
                        onClick={() => setModalVisible(true)}
                        src={settingIcon}
                    />
                </Tooltip>
            </div>

            {/* 设置弹窗 */}
            <Modal
                title={
                    <div
                        style={{
                            borderBottom: "1px solid #E7E7E7",
                            paddingBottom: "8px",
                        }}
                    >
                        设置
                    </div>
                }
                className="modal"
                open={modalVisible}
                mask={false}
                getContainer={(isMobile || layout === 'vertical') ? pageRef.current : document.body}
                footer={
                    <Space>
                        <Button onClick={() => setModalVisible(false)}>
                            取消
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => handleSaveSetting()}
                        >
                            确定
                        </Button>
                    </Space>
                }
                onOk={() => setModalVisible(false)}
                onCancel={() => setModalVisible(false)}
            >
                <Tabs
                    items={items}
                    activeKey={tabIndex}
                    onChange={handleTabsChange}
                    tabBarStyle={{
                        borderBottom: "none",
                    }}
                />
            </Modal>
        </div>
    );
}
export default DigitalPeople;
